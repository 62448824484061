<mat-form-field [class]="'resize-form-field ' + className">
  <mat-label>Search</mat-label>
  <mat-chip-grid #chipGrid class="chip-grid">
    <div class="scrollContainer" #scrollContainer>
      <mat-chip-row
        *ngFor="let item of listItemSearched; let i = index"
        (removed)="remove(item)"
        (click)="item.type !== typeField.input && edit(item, i)"
        class="chip-grid !max-w-fit !bg-[#E3E5E8] !my-0"
      >
        <span *ngIf="item.key !== searchAny; else valueANY">{{
          item.key + (item.type === typeField.boolean ? '' : ': ' + item.value)
        }}</span>
        <ng-template #valueANY
          ><span>{{ item.value }}</span></ng-template
        >
        <button *ngIf="item.value" matChipRemove class="!pl-1">
          <mat-icon>close</mat-icon>
        </button>
      </mat-chip-row>
    </div>
    <input
      #searchInput
      [formControl]="searchCtrl"
      [matChipInputFor]="chipGrid"
      [matAutocomplete]="auto"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="$event.value && addInput($event)"
      [matChipInputAddOnBlur]="true"
      [readonly]="isReadOnlyInput"
      (focus)="resizeField(true); onFocus()"
      (blur)="
        resizeField(false);
        !this.dataOfItemFilter?.length && this.isReadOnlyInput && (isShowFilter = true);
        !autocompleteTrigger.autocomplete.isOpen && onSearch()
      "
      [class]="'!flex-[1_0_50px]'"
    />
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" (closed)="closedAutoComplete()">
    <!-- Show list item search filter -->
    <ng-container *ngIf="isShowFilter">
      <ng-container *ngFor="let item of filterItemResource">
        <mat-option *ngIf="!item.isInvisibility" [value]="item" class="item-filter">
          <div class="flex justify-between items-center !w-full">
            <span class="!bg-[#E3E5E8] px-2 rounded-md">{{ item.key }}</span
            ><span>{{ item.info }}</span>
          </div>
        </mat-option>
      </ng-container>
    </ng-container>

    <!-- Show list option data of item search filter (selection) -->
    <ng-container *ngIf="!isShowFilter">
      <mat-option *ngFor="let item of dataOfItemFilter" [value]="item" class="item-filter">
        <ng-container *ngIf="!isMultiSelect; else multi">
          {{ item.value }}
        </ng-container>
        <ng-template #multi>
          <div (click)="optionClicked($event, item)">
            <mat-checkbox [checked]="item.selected" (change)="toggleSelection(item)" (click)="$event.stopPropagation()">
              {{ item.value }}
            </mat-checkbox>
          </div>
        </ng-template>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
  <button *ngIf="listItemSearched?.length" (click)="clearAllSearchFilter()" mat-icon-button matSuffix>
    <mat-icon class="s-20 mr-3 !text-[#575A5C]"> clear </mat-icon>
  </button>
  <button
    mat-icon-button
    matSuffix
    (click)="autocompleteTrigger.closePanel(); inputField.nativeElement.blur(); onSearch()"
  >
    <mat-icon class="s-20 mr-3 !text-[#575A5C]">search</mat-icon>
  </button>
</mat-form-field>
