export enum AppNamePermission {
  sip = 'sip',
  autoAttendant = 'autoAttendant',
  call = 'call',
  fax = 'fax',
  byop = 'byop',
  byoc = 'byoc',
  hotDesking = 'hotDesking',
  leave = 'leave',
  consent = 'consent',
  language = 'language'
}

export enum AppNameActionPermission {
  viewSip = 'viewSip',
  modifySip = 'modifySip',
  grantSipPermissions = 'grantSipPermissions',
  viewFlow = 'viewFlow',
  editFlow = 'editFlow',
  deployFlow = 'deployFlow',
  grantAutoAttendantPermissions = 'grantAutoAttendantPermissions',
  viewFax = 'viewFax',
  modifyFax = 'modifyFax',
  grantFaxPermissions = 'grantFaxPermissions',

  // BYOP Permissions ----------------
  viewByop = 'viewByop',
  modifyByop = 'modifyByop',
  grantByopPermissions = 'grantByopPermissions',

  // BYOC Permissions ----------------
  viewByoc = 'viewByoc',
  modifyByoc = 'modifyByoc',
  grantByocPermissions = 'grantByocPermissions',

  // hotDesking Permissions ----------------
  viewHotdesking = 'viewHotdesking',
  modifyHotdesking = 'modifyHotdesking',
  grantHotdeskingPermissions = 'grantHotdeskingPermissions',

  // leave Permissions ----------------
  manageLeave = 'manageLeave',

  // Consent Permission ------------
  viewConsent = 'viewConsent',
  modifyConsent = 'modifyConsent',

  // language Permissions ----------------
  viewLanguages = 'viewLanguages',
  modifyLanguages = 'modifyLanguages'
}
