export interface FilterItemSearch {
  key: string;
  value: any;
  valueResourceKey?: any;
  info?: string;
  type?: TypeField;
  order?: number;
  data?: ResourceItem[];
  isInvisibility?: boolean;
  isDisable?: boolean;
}

export interface ResourceItem {
  key: any;
  value: any;
  selected?: boolean;
}

export enum TypeField {
  input = 'input',
  singleSelect = 'singleSelect',
  multiSelect = 'multiSelect',
  boolean = 'boolean'
}

export const SEARCH_ANY = 'Any';
