import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Contact } from '@b3networks/api/contact';
import { UserDirectory } from '@b3networks/api/directory';
import { User } from '@b3networks/api/workspace';
import { DomainUtilsService, LocalStorageUtil, ReconnectChatStrageryV2 } from '@b3networks/shared/common';
import { UA, WebSocketInterface, debug } from 'jssip';
import { DTMF_TRANSPORT } from 'jssip/lib/Constants';
import { EndEvent, RTCSession, SendingEvent } from 'jssip/lib/RTCSession';
import {
  CallOptions,
  IncomingRTCSessionEvent,
  OutgoingRTCSessionEvent,
  UAConfiguration,
  UnRegisteredEvent
} from 'jssip/lib/UA';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { TypeSound } from '../audio-player/audio-player.model';
import { AudioPlayerService } from './../audio-player/audio-player.service';
import {
  OptionsAdditional,
  Originator,
  SessionDirection,
  SessionManageState,
  SipResponse,
  TimerCall,
  UAEventStatus,
  WebrtcInitData,
  WebrtcState
} from './webrtc.model';
import { WebrtcQuery } from './webrtc.query';
import { WebrtcStore } from './webrtc.store';

// declare let JSSip: any;

export const EXPIRY_CREDENTIAL_ACCOUNT_INSECONDS = 8 * 60 * 60; // 8h
export const EXPIRY_REGISTER_SIP_INSECONDS = 30 * 60; // 30m
export const KEY_CACHE_CREDENTIAL_ACCOUNT = 'credential-account-v2';

export interface WebRTCConfig {
  noInbound?: boolean;
  noOutbound?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class WebrtcService {
  keyCache: string;
  config: WebRTCConfig = <WebRTCConfig>{
    noInbound: false,
    noOutbound: false
  };

  reconnectWhenDisconnected = new ReconnectChatStrageryV2({
    maxReconnect: 15
  });

  private _isForceDisconnect = false;
  private _RTCConstraints = {
    optional: [],
    mandatory: {
      offerToReceiveAudio: true,
      offerToReceiveVideo: false
    }
  };
  private _callOptions = <CallOptions>{
    mediaConstraints: { audio: true, video: false },
    pcConfig: {
      iceServers: []
    },
    rtcOfferConstraints: this._RTCConstraints,
    eventHandlers: {
      sending: (event: SendingEvent) => {
        console.log('🚀 ~ event', event);
      }
    },
    extraHeaders: []
  };

  private _socket: WebSocketInterface;
  private _listSocketInited: WebSocketInterface[] = [];
  private _cred: WebrtcInitData;
  private _isConnecting: boolean;
  private _dtmfQueue: string;
  private _isEnableCallWaiting = false;

  constructor(
    private http: HttpClient,
    private store: WebrtcStore,
    private query: WebrtcQuery,
    private audioPLayerService: AudioPlayerService,
    private domainUtilsService: DomainUtilsService
  ) {
    debug.disable();
    // debug.enable('JsSIP:*');
    console.log(this);
  }

  init(key: string, config: WebRTCConfig = <WebRTCConfig>{}, focusRenew = false): Observable<UA> {
    this.config = {
      ...this.config,
      ...config
    };

    this._isConnecting = true;

    let credCache;
    if (!focusRenew && key) {
      try {
        credCache = JSON.parse(LocalStorageUtil.getItem(key));
      } catch (error) {
        console.log('🚀 ~ error', error);
      }
    }

    return (
      credCache
        ? of(new WebrtcInitData(credCache))
        : this.generateSipRTC().pipe(
            tap((cred: WebrtcInitData) => {
              LocalStorageUtil.setItem(key, JSON.stringify(cred), cred.expiredAt); // cache credential account
            })
          )
    ).pipe(
      switchMap((cred: WebrtcInitData) => {
        this._cred = cred;
        console.log('cred: ', this._cred, 'from cache:', !!credCache);
        this.keyCache = key;

        this.setupUAConfiguration();
        try {
          this.start();
        } catch (error) {
          console.error('🚀 ~ error', error);
          return of(null);
        }

        return of(this.query.UA);
      })
    );
  }

  initWithJWT(cred: WebrtcInitData, config: WebRTCConfig = <WebRTCConfig>{}) {
    this.config = {
      ...this.config,
      ...config
    };

    this._cred = cred;
    this.reconnectWhenDisconnected.reset();
    this.setupUAConfiguration();
    try {
      this.start();
    } catch (error) {
      console.error('🚀 ~ error', error);
    }
  }

  updateUAConfiguration<T extends keyof UAConfiguration>(parameter: T, value: UAConfiguration[T]) {
    this.query?.UA?.set(parameter, value);
  }

  updateStatusEnableCallWaiting(enableCallWaiting: boolean) {
    this._isEnableCallWaiting = enableCallWaiting;
  }

  doDTMF(tone: number | string, sleep = 600) {
    if (!tone) {
      return;
    }

    if (this._dtmfQueue) {
      this._dtmfQueue += tone.toString();
      return;
    }
    this._dtmfQueue = tone.toString();
    try {
      this._sendQueuedDTMF(0);
    } catch (error) {
      console.error(error);
    }
  }

  doRejectCall() {
    const session = this.query.session;
    session?.terminate({
      status_code: 486,
      reason_phrase: 'Rejected Call'
    });
  }

  doToggleHold() {
    const currentHold = this.query.callManagement.isHold;
    // switch value
    this.store.update(state => ({
      ...state,
      callManagement: {
        ...state.callManagement,
        isHold: !currentHold
      }
    }));
    const session = this.query.session;
    if (currentHold) {
      session?.unhold();
    } else {
      session?.hold();
    }
  }

  doToggleMute() {
    const currentMute = this.query.callManagement.isMute;
    // switch value
    this.store.update(state => ({
      ...state,
      callManagement: {
        ...state.callManagement,
        isMute: !currentMute
      }
    }));
    const session = this.query.session;
    if (currentMute) {
      session?.unmute();
    } else {
      session?.mute();
    }
  }

  updateRoom(isRoom: boolean) {
    this.store.update(state => ({
      ...state,
      callManagement: {
        ...state.callManagement,
        isRoom: isRoom
      }
    }));
  }

  doAnswerIncoming() {
    const session = this.query.session;
    session.answer(this._callOptions);
  }

  doAnswerWaitingIncoming(session: RTCSession) {
    this.query.session?.terminate({
      status_code: 486,
      reason_phrase: 'Rejected Call'
    });
    session.answer(this._callOptions);
  }

  doRejectWaitingCall(session: RTCSession) {
    session?.terminate({
      status_code: 486,
      reason_phrase: 'Rejected Call'
    });
  }

  handleCall(session: RTCSession) {
    // initial UI
    !this.query?.session &&
      this._updateWebRTCState({
        session: session,
        callManagement: {
          ...this.query.callManagement,
          isRemote: session.direction === SessionDirection.INCOMING,
          timerCall: new TimerCall()
        },
        sessionsWaiting: []
      });

    if (session.id !== this.query?.session?.id && this.query?.session) {
      const listSessions: SessionManageState[] = this.query.sessionsWaiting || [];
      const sessionFound = (listSessions || []).find(s => s.session.id === session.id);
      !sessionFound &&
        listSessions.push({
          session,
          callManagement: { isRemote: session.direction === SessionDirection.INCOMING, timerCall: new TimerCall() }
        } as SessionManageState);
      this._updateWebRTCState({ sessionsWaiting: [...listSessions] });
    }

    session.on('connecting', () => {
      console.log('handleCall status: connecting');
      this.store.update(state => ({ ...state, session: session }));
    });

    session.on('progress', () => {
      console.log('handleCall status: progress');
      if (session.direction === SessionDirection.INCOMING) this.audioPLayerService.play(TypeSound.ringback, true);
      this._updateWebRTCState({
        callManagement: {
          ...this.query.callManagement,
          ringing: true
        }
      });
    });

    session.on('failed', (event: EndEvent) => {
      console.log('handleCall status: failed', event);
      this._updateWebRTCState({ statusUA: { status: UAEventStatus.failed, reason: event?.cause } });
      if (this.query.session.id === session.id) {
        this.audioPLayerService.play(TypeSound.rejected);
        this.query.callManagement.timerCall.clearIntervalTime();
        const nextSessionManage = this.query.sessionsWaiting?.[0];
        const sessionsWaiting = (this.query.sessionsWaiting || []).filter(
          s => s.session.id !== nextSessionManage.session?.id
        );
        nextSessionManage && this.audioPLayerService.play(TypeSound.ringback, true);
        this._updateWebRTCState({
          session: nextSessionManage?.session,
          callManagement: nextSessionManage?.callManagement,
          sessionsWaiting
        });
      } else {
        this.audioPLayerService.stop();
        const sessionsWaiting = (this.query.sessionsWaiting || []).filter(s => s.session.id !== session.id);
        this._updateWebRTCState({ sessionsWaiting });
      }

      if (event?.originator === Originator.REMOTE) {
        event?.cause === 'Authentication Error' && this.init(this.keyCache, {}, true).subscribe();
      }
    });

    session.on('ended', () => {
      console.log('handleCall status: ended');
      if (this.query.session.id === session.id) {
        this.audioPLayerService.play(TypeSound.rejected);
        this.query.callManagement.timerCall.clearIntervalTime();
        const nextSessionManage = this.query.sessionsWaiting?.[0];
        const sessionsWaiting = (this.query.sessionsWaiting || []).filter(
          s => s.session.id !== nextSessionManage.session?.id
        );
        nextSessionManage && this.audioPLayerService.play(TypeSound.ringback, true);
        this._updateWebRTCState({
          session: nextSessionManage?.session,
          callManagement: nextSessionManage?.callManagement,
          sessionsWaiting
        });
      } else {
        const sessionsWaiting = (this.query.sessionsWaiting || []).filter(s => s.session.id !== session.id);
        this._updateWebRTCState({ sessionsWaiting });
        console.log('end', session, sessionsWaiting);
      }
    });

    session.on('accepted', () => {
      console.log('handleCall status: accepted');
      this.audioPLayerService.stop();
      const sessionsWaiting = (this.query.sessionsWaiting || []).filter(s => s.session.id !== session.id);
      this._updateWebRTCState({
        session: session,
        sessionsWaiting,
        callManagement: {
          ...this.query.callManagement,
          canHold: true,
          canDTMF: true,
          ringing: false
        }
      });
      this.query.callManagement.timerCall.countTimeCall(() => this.query.callDurationChanged$.next());
    });

    session.on('hold', () => {
      this.audioPLayerService.play(TypeSound.hold, true);
      this._updateWebRTCState({
        callManagement: {
          ...this.query.callManagement,
          isHold: true
        }
      });
    });

    session.on('unhold', () => {
      this.audioPLayerService.play(TypeSound.answered);
      this._updateWebRTCState({
        callManagement: {
          ...this.query.callManagement,
          isHold: false
        }
      });
    });
  }

  makeCallOutgoing(number: string, member: Contact | UserDirectory, isMeeting?: boolean) {
    // Avoid if busy or other incoming
    if (!number || this.query.session) {
      return;
    }
    if (isMeeting) {
      this.updateRoom(true);
    }

    if (member) {
      this.store.update(data => ({
        ...data,
        callManagement: {
          ...data.callManagement,
          member: member instanceof Contact ? new Contact(member) : new UserDirectory(member)
        }
      }));
    }

    const formatNumber = parsePhoneNumberFromString(number);
    this.query.UA.call(formatNumber ? formatNumber.number : number, this._callOptions);
  }

  makeCallOutgoingV2(number: string, member: Contact | User, options: OptionsAdditional) {
    // Avoid if busy or other incoming
    if (!number || this.query.session) return;

    if (member) {
      this.store.update(data => ({
        ...data,
        callManagement: {
          ...data.callManagement,
          member: member instanceof Contact ? new Contact(member) : new UserDirectory(member)
        }
      }));
    }

    const formatNumber = parsePhoneNumberFromString(number);

    const callOptionClone = JSON.parse(JSON.stringify(this._callOptions));
    callOptionClone.extraHeaders.push(...(options?.extraHeaders || []));
    this.query.UA.call(formatNumber ? formatNumber.number : number, callOptionClone);
  }

  restartWebsocketAndRegister() {
    console.log('restartWebsocketAndRegister: ');
    this._socket?.disconnect();
    this._isForceDisconnect = true;
    this.query.UA.stop();
    this.query.UA.start();
  }

  private _updateWebRTCState(app: Partial<WebrtcState>) {
    this.store.update(state => {
      return { ...state, ...app };
    });
  }

  private start() {
    console.log('start: ');

    if (this.query.UA?.isRegistered()) {
      throw 'Start failed';
    }

    const authorizationCurrent = this._cred.sipUsername;
    this.query.UA.on('connecting', () => {
      if (authorizationCurrent !== this._cred.sipUsername) {
        return;
      }

      this.store.update({ statusUA: { status: UAEventStatus.connecting } });
    });

    this.query.UA.on('connected', () => {
      if (authorizationCurrent !== this._cred.sipUsername) {
        return;
      }

      this._isConnecting = false;
      this.store.update({ statusUA: { status: UAEventStatus.connected } });
      this.query.UA.register();
      this.cleanUpOldWs();
      this.reconnectWhenDisconnected.reset();
    });

    this.query.UA.on('disconnected', (event: any) => {
      if (authorizationCurrent !== this._cred.sipUsername) {
        return;
      }

      console.log('disconnected WEBRTC socket: ', event);
      this.store.update({ statusUA: { status: UAEventStatus.disconnected } });
      if (!this._isForceDisconnect) {
        this.reconnectJSSipWhenDisconnected();
      } else {
        this._isForceDisconnect = false;
      }
    });

    this.query.UA.on('registered', () => {
      if (authorizationCurrent !== this._cred.sipUsername) {
        return;
      }
      this.store.update({ statusUA: { status: UAEventStatus.registered } });
    });

    this.query.UA.on('unregistered', () => {
      if (authorizationCurrent !== this._cred.sipUsername) {
        return;
      }

      this.store.update({ statusUA: { status: UAEventStatus.unregistered } });
    });

    this.query.UA.on('registrationFailed', (event: UnRegisteredEvent) => {
      if (authorizationCurrent !== this._cred.sipUsername) {
        return;
      }

      console.log('registrationFailed: ', event);
      this.store.update({ statusUA: { status: UAEventStatus.registrationFailed } });

      if (event?.response?.status_code === 401) {
        this.init(this.keyCache, {}, true).subscribe();
      } else {
        this.reconnectJSSipWhenDisconnected();
      }
    });

    this.query.UA.on('registrationExpiring', () => {
      if (authorizationCurrent !== this._cred.sipUsername) {
        return;
      }

      this.store.update({ statusUA: { status: UAEventStatus.registrationExpiring } });
      this.query.UA.register();
    });

    this.query.UA.on('newRTCSession', (data: IncomingRTCSessionEvent | OutgoingRTCSessionEvent) => {
      console.log('newRTCSession: ', data);
      if (authorizationCurrent !== this._cred.sipUsername) {
        return;
      }

      if (
        (data.originator === Originator.REMOTE && !this.config?.noInbound) ||
        (data.originator === Originator.LOCAL && !this.config?.noOutbound)
      ) {
        this.query.session &&
          !this._isEnableCallWaiting &&
          data.session.terminate({
            status_code: 486,
            reason_phrase: 'Busy Here'
          });
        this.handleCall(data.session);
      }
    });

    // register
    this.query.UA.start();
  }

  private reconnectJSSipWhenDisconnected() {
    console.log('this.reconnectWhenDisconnected: ', this.reconnectWhenDisconnected, this._isConnecting);
    if (this.reconnectWhenDisconnected.canReconnect) {
      if (!this._isConnecting) {
        setTimeout(() => {
          this.restartWebsocketAndRegister();
        }, this.reconnectWhenDisconnected.waitingTime);

        this.reconnectWhenDisconnected.increaseReconnectTime();
      }
    } else {
      console.log('JSSip reached reconnect times...');
    }
  }

  private setupUAConfiguration() {
    this._socket?.disconnect();

    this._socket = new WebSocketInterface(this._cred.wsUrl);
    this._listSocketInited.push(this._socket);
    const configuration: UAConfiguration = {
      sockets: this._socket,
      uri: null,
      session_timers: false,
      user_agent: 'B3networks',
      register_expires: EXPIRY_REGISTER_SIP_INSECONDS,
      no_answer_timeout: 120,
      register: false
    };

    if (this._cred.sipUsername) {
      configuration.authorization_user = this._cred.sipUsername;
      configuration.uri = this._cred.getSipAddress();
    }
    if (this._cred.sipPassword) configuration.password = this._cred.sipPassword;

    this.store.update({ ua: new UA(configuration) });

    if (this._cred.jwt) {
      this.query.UA.registrator()?.setExtraHeaders([`X-Authorization: Bearer ${this._cred.jwt}`]);
      this._callOptions.extraHeaders.push(`X-Authorization: Bearer ${this._cred.jwt}`);
    }
  }

  private generateSipRTC(): Observable<WebrtcInitData> {
    return this.http
      .post<SipResponse>('/call/private/v1/webrtc/generate', {
        expiry: EXPIRY_CREDENTIAL_ACCOUNT_INSECONDS
      })
      .pipe(
        map(
          sip =>
            new WebrtcInitData({
              username: sip.username,
              endpoint: sip.domain,
              port: null,
              sipUsername: sip.fullUsername,
              sipPassword: sip.password,
              userAgent: 'B3networks',
              sturn: 'stun:stun.b3networks.com',
              domain: this.domainUtilsService.getPortalDomain(),
              expiredAt: sip.expiredAt
            })
        )
      );
  }

  private _sendQueuedDTMF(sleep: number) {
    if (!this._dtmfQueue || this._dtmfQueue?.length === 0) {
      this._dtmfQueue = undefined;
      return;
    }
    const tone = this._dtmfQueue.charAt(0);
    const session = this.query.session;
    if (session) {
      console.log('sendDTMF', tone);
      try {
        session?.sendDTMF(tone, { duration: 100, interToneGap: 50, transportType: DTMF_TRANSPORT.RFC2833 });
      } catch (error) {
        console.error(error);
      }
      // this.audioPLayerService.play(TypeSound.dial);
    }

    setTimeout(() => {
      this._dtmfQueue = this._dtmfQueue.substring(1);
      this._sendQueuedDTMF(sleep);
    }, sleep);
  }

  private cleanUpOldWs() {
    setTimeout(() => {
      this._listSocketInited?.forEach(socket => {
        if (socket.url !== this._socket?.url) {
          socket.disconnect();
        }
      });
    }, 2000);
  }
}
