import { Injectable } from '@angular/core';
import { EntityUIQuery, ID, Order, QueryEntity } from '@datorama/akita';
import { filter, map } from 'rxjs/operators';
import { Privacy } from '../enums.model';
import { ChannelState, ChannelStore, ChannelUIState } from './channel.store';
import { ChannelUI } from './model/channel-ui.model';
import { Channel } from './model/channel.model';
import { ChannelType, NameChannelPersonal } from './model/enum-channel.model';

@Injectable({ providedIn: 'root' })
export class ChannelQuery extends QueryEntity<ChannelState> {
  override ui: EntityUIQuery<ChannelUIState>;

  loadedMineChannel$ = this.select(entity => entity.loadedMineChannel).pipe(filter(x => x));
  loaded$ = this.select(entity => entity.loaded).pipe(filter(x => x));
  isDisconnected$ = this.select(entity => entity.isDisconnected);
  recentChannels$ = this.select(entity => entity.recentChannels);

  constructor(protected override store: ChannelStore) {
    super(store);
    this.createUIQuery();
    console.log(this);
  }

  storeLoaded() {
    return this.store.getValue()?.loaded;
  }

  storeIsDisconnected() {
    return this.store.getValue()?.isDisconnected;
  }

  getPersonalChannel(channelName: NameChannelPersonal | string) {
    return this.getAll({
      filterBy: entity => entity.type === ChannelType.PERSONAL && entity.name === channelName
    })[0];
  }

  selectPersonalChannel(channelName: NameChannelPersonal | string) {
    return this.selectAll({
      filterBy: entity => entity.type === ChannelType.PERSONAL && entity.name === channelName,
      limitTo: 1
    }).pipe(map(x => x[0]));
  }

  getGeneral() {
    return this.getAll({
      filterBy: entity => entity.name === 'general',
      limitTo: 1
    });
  }

  selectGeneral() {
    return this.selectAll({
      filterBy: entity => entity.name === 'general',
      limitTo: 1
    });
  }

  getDirectChannels() {
    return this.getAll({
      filterBy: entity => entity.type === ChannelType.dm
    });
  }

  selectPropertyChannel<K extends keyof Channel>(id: string, property: K) {
    return this.selectEntity(id, property);
  }

  getState() {
    return this.getValue();
  }

  getChannelUiState(channelId: string | ID) {
    return this.ui.getEntity(channelId);
  }

  selectChannelUiState(channelId: string) {
    return this.ui.selectEntity(channelId);
  }

  selectUIState<K extends keyof ChannelUI>(id: string | ID, property: K) {
    return this.ui.selectEntity(id, property);
  }

  getChannel(channelId: string) {
    return this.getEntity(channelId);
  }

  selectChannel(channelId: string) {
    return this.selectEntity(channelId);
  }

  // User.userUuid
  findChannelDirectChatWithMe(userUuid: string) {
    const find = this.getAll({
      filterBy: entity => entity.type === ChannelType.dm && entity.directChatUsers.otherUuid === userUuid,
      limitTo: 1
    });
    return find ? find[0] : null;
  }

  selectCountUnreadBadge(userUuids: string[]) {
    return this.selectCount(entity => {
      const dm = entity.type === ChannelType.dm && entity.unreadCount > 0;
      if (dm) {
        // because direct chat with disactive user don't display
        if (userUuids.includes(entity?.directChatUsers?.otherUuid)) {
          return dm;
        }
        return false;
      }

      const myChannel = entity.isMyChannel && !entity.isArchived;
      const gc = myChannel && entity.type === ChannelType.gc && entity.mentionCount > 0;
      return gc;
    });
  }

  selectCountUnreadBadgeV2() {
    return this.selectCount(entity => {
      const dm = entity.type === ChannelType.dm && entity.unreadCount > 0;
      if (dm) {
        // because direct chat with disactive user don't display
        if (!entity.isDisableUser) {
          return true;
        }
        return false;
      }

      return entity.type === ChannelType.gc && entity.isMyChannel && !entity.isArchived && entity.mentionCount > 0;
    });
  }

  selectCountThreadsActive(parentId: string) {
    return this.selectCount(entity => entity.isThread && entity.parentId === parentId && !entity.closedL2);
  }

  selectThreadsCLose(parentId: string) {
    return this.selectAll({
      filterBy: entity => entity.isThread && entity.parentId === parentId && !!entity.closedL2,
      sortBy: 'closedAt',
      sortByOrder: Order.DESC
    });
  }

  selectThreadsCLoseByUser() {
    return this.selectAll({
      filterBy: entity => entity.isThread && !!entity.closedL2,
      sortBy: 'closedAt',
      sortByOrder: Order.DESC
    });
  }

  selectCountMyChannel() {
    return this.selectCount(entity => entity.isMyChannel && [ChannelType.dm, ChannelType.gc].includes(entity.type));
  }

  getAllGroupMyChannel() {
    return this.getAll({
      filterBy: entity => entity.isMyChannel && [ChannelType.gc].includes(entity.type),
      sortBy: (a, b) => a.displayName.localeCompare(b.displayName),
      sortByOrder: Order.ASC
    });
  }

  getAllIdByStarredHasUnread(isStarred: boolean) {
    return this.getAll({
      filterBy: entity =>
        entity.unreadCount > 0 &&
        entity.isMyChannel &&
        !entity.isArchived &&
        [ChannelType.gc].includes(entity.type) &&
        (isStarred ? entity.isStarred : !entity.isStarred),
      sortBy: (a, b) => b.lastMessage?.ts - a.lastMessage?.ts,
      sortByOrder: Order.ASC
    });
  }

  getAllChannelArchived() {
    return this.getAll({
      filterBy: entity => entity.isMyChannel && entity.isArchived && [ChannelType.gc].includes(entity.type),
      sortBy: (a, b) => a.displayName.localeCompare(b.displayName),
      sortByOrder: Order.ASC
    });
  }

  getAllPublicNotJoin(limit: number) {
    return this.getAll({
      filterBy: entity =>
        [ChannelType.gc].includes(entity.type) && Privacy.public && !entity.isMyChannel && !entity.isArchived,
      sortBy: (a, b) => a.displayName.localeCompare(b.displayName),
      sortByOrder: Order.ASC,
      limitTo: limit
    });
  }
}
