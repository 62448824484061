import { KeyValue } from '@angular/common';
import { ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Channel, ChannelHyperspace, ChatMessage, ReplyMessage } from '@b3networks/api/chat';
import { ChannelStateV3, ChannelStateV4, Contact } from '@b3networks/api/contact';
import { UserDirectory } from '@b3networks/api/directory';
import { Inbox, Txn, TxnChannel, TxnStatus, ViewMode } from '@b3networks/api/inbox';
import { PER_PAGE_TXN } from '@b3networks/shared/common';
import { ID } from '@datorama/akita';

export interface AppState {
  sidebarTabActive?: SidebarTabs;
  emailUWState?: EmailUWState;

  showRightSidebar: boolean;
  modeRightSidebar: ModeSidebar;
  isLeftChatSidebar: boolean;
  isLeftCallSidebar: boolean;

  mentionCountTeamChat: number;
  quillEditor: {
    triggerfocus: boolean;
  };
  triggerScrollBottomView: boolean;
  triggerDoCheckScroll: boolean;

  memberMenu: InfoShowMention;

  popupState: PopupState;
  loadingQueryTxn: boolean;
  initHeightTxn: number;
  triggerRefreshTxn: boolean;
  triggerScrollUpTxn: boolean;
  triggerRefreshQueryTxn: boolean;
  triggerRefreshActiveCall: boolean;
  customerTxnReports: Contact[];

  collapseSidebar: CollapseSidebar;

  triggerIsScrollDownTxn: boolean;

  // TODO: cache with convo;
  triggerSeeAllPinned: boolean;

  splitMode: SplitMode;
  isFetchingTxnDetail: boolean; // show loading when switch another txn
  isFetchingQueryTxns: boolean;
  isSmallBreakpont: boolean;

  currentWidthDiscussion: number; //width for interal discussion
  recentTxns: Txn[];
  isOpenPreviewNoAvailable: boolean; // show content when click files can not preview

  isOpenLeftSidebar: boolean;
  isOpenInternalSpace: boolean;
  osName: OS;
  discoveryChannelState: DiscoveryChannelState;

  // omni
  channelsByLicense: TxnChannel[]; // baseon license of org
  viewTxn: ViewTxn;
  latestViewMyWorks: ViewTxn;
  sectionOmni: SectionOmni;
  myWorkV2: MyWorkV2; //view my work
  pendingTxns: PendingTxns[]; // inboxUuid => txnUuids
}

export type MyWorkV2 = { [key in ViewTxn]: TxnFilterStateV1 };

export interface CollapseSidebar {
  starred: boolean;
  me: boolean;
  inboxes: boolean;
  watching: boolean;
  related: boolean;
  created: boolean;
  mentions: boolean;
  channel: boolean;
  customerJourneys: boolean;
  channels: boolean;
}

export interface RightCLickMessage {
  uuid: string; // identity

  menus: MenuMessageInfo[];
  message: ChatMessage;

  xPosition: number;
  yPosition: number;
  elr: ElementRef;
}

export interface MenuMessageInfo {
  key: MenuMsg;
  showHTML: boolean;
  value: string;
  order: number;
  icon: string;
  size: number; // px
  isSvg: boolean;
  classIcon: string;
  classText: string;

  // input data
  dataReply?: ReplyMessage; // MenuMsg.reply
  targetCopy?: string; // MenuMsg.copyText
  targetLink?: string; // MenuMsg.openTab, MenuMsg.copyLinkAddress

  // MenuMsg.deleteMessage
  deleteMessage?: {
    isPinned: boolean;
  };
}

export interface PopupState {
  isShowPopup: boolean;
  isLoadingTemplate: boolean;
  tag: { [key: string]: string | string[] };
  systemStatusExpiredAt: number;
  remainTime: number;
}

export enum MenuMsg {
  // message
  editMessage = 'editMessage',
  reply = 'reply',
  pinMsg = 'pinMsg',
  unpinMsg = 'unpinMsg',
  createThread = 'createThread',
  copyText = 'copyText',
  copySelectedText = 'copySelectedText',
  copyLinkAddress = 'copyLinkAddress',
  removePreviewLink = 'removePreviewLink',
  deleteMessage = 'deleteMessage',
  jumpFromBookmark = 'jumpFromBookmark',
  removeBookmark = 'removeBookmark',
  addBookmark = 'addBookmark',

  // jumpReply = 'jumpReply',
  copyLink = 'copyLink',

  // files
  showInChat = 'showInChat',
  downloadFile = 'downloadFile'
}

export interface InfoShowMention {
  xPosition: number;
  yPosition: number;
  member: UserDirectory;
  convo: Channel | ChannelHyperspace;
}

// use for define path + routing with path currently
export enum SectionOmni {
  inboxes = 'inboxes',
  myWork = 'my-work'
}

export enum ViewTxn {
  report = 'report', //view all transaction
  inboxes = 'inboxes', //view Inboxes

  // View my works
  assigned = 'assigned', // default
  createdByMe = 'createdByMe',
  watcher = 'watcher',
  mention = 'mention',
  worked_on = 'worked_on'
}

export enum ModeSidebar {
  over = 'over',
  side = 'side',
  push = 'push'
}

export enum SidebarTabs {
  teamchat = 'teamchat',
  email = 'email'
}

export enum SplitMode {
  horizontalSplit = 'horizontalSplit', // default
  noSplit = 'noSplit'
}

// TODO: merge
export interface DiscoveryChannelState extends ChannelStateV5 {}

export interface EmailUWState {
  isExpandPersonal: boolean;
  isExpandTeam: boolean;
  isExpandTeammate: boolean;
}

export interface PendingTxns {
  txnUuid: string | ID;
  inboxUuid: string;
  channel: TxnChannel;
}

export interface TxnFilterState {
  txnFilter?: ChannelStateV4 | ChannelStateV3;
  txnUuidsFilter?: string[];
}

export interface TxnFilterStateV1 {
  state: ChannelStateV5;
  txnFilter: FilterTxnFormGroupV1;
  txnUuidsFilter: string[];
  loadedFirst?: boolean;
}

export interface ChannelStateV5 {
  loaded?: boolean;
  hasMore?: boolean;
  page: number;
  perPage: number;
}

export interface FilterTxnFormGroupControl {
  statuses: FormControl<KeyValue<TxnStatus, string>[]>;
  inboxUuids: FormControl<Inbox[]>;
  channels: FormControl<KeyValue<TxnChannel, string>[]>;
  singleChannel: FormControl<KeyValue<TxnChannel, string>>;
  viewMode: FormControl<ViewMode>;

  textSearch: FormControl<string>;
  searchBy: FormControl<SearchBy>;

  reporters: FormControl<AllUsersFilterSuggestion[]>;
  assignees: FormControl<AllUsersFilterSuggestion[]>;
}

export interface FilterTxnFormGroupV1 {
  statuses: KeyValue<TxnStatus, string>[];
  inboxUuids: Inbox[];
  channels: KeyValue<TxnChannel, string>[];
  singleChannel: KeyValue<TxnChannel, string>;
  viewMode: ViewMode;

  textSearch: string;
  searchBy: SearchBy;

  reporters: AllUsersFilterSuggestion[];
  assignees: AllUsersFilterSuggestion[];
}

export interface AllUsersFilterSuggestion {
  uuid: string;
  displayName: string;
  photoUrl: string;
  isUser: boolean;
  isBot: boolean;
  isOrg: boolean;
  icon: string;
}
export const ASSIGNED_OPT_FILTER: KeyValue<TxnStatus, string> = { key: TxnStatus.assigned, value: 'Assigned' };
export const mapStatusOpts: { [key in TxnStatus]?: KeyValue<TxnStatus, string> } = {
  [TxnStatus.waiting]: { key: TxnStatus.waiting, value: 'Waiting' },
  [TxnStatus.assigning]: { key: TxnStatus.assigning, value: 'Assigning' },
  [TxnStatus.assigned]: { key: TxnStatus.assigned, value: 'Assigned' },
  [TxnStatus.transferring]: { key: TxnStatus.transferring, value: 'Transferring' },
  [TxnStatus.ended]: { key: TxnStatus.ended, value: 'Ended' },
  [TxnStatus.failed]: { key: TxnStatus.failed, value: 'Failed' }
};
// share to set filter for form when init component
export const mapChannelOpts: { [key in TxnChannel]?: KeyValue<TxnChannel, string> } = {
  [TxnChannel.livechat]: {
    key: TxnChannel.livechat,
    value: 'Live chat'
  },
  [TxnChannel.whatsapp]: {
    key: TxnChannel.whatsapp,
    value: 'WhatsApp'
  },
  [TxnChannel.supportCenter]: {
    key: TxnChannel.supportCenter,
    value: 'Support ticket'
  },
  [TxnChannel.call]: {
    key: TxnChannel.call,
    value: 'Call'
  },
  [TxnChannel.email]: {
    key: TxnChannel.email,
    value: 'Mail'
  }
};

export const NameAppStore = 'workspace_app_state';
export const PER_PAGE_DISCOVERY_CHANNEL = 19;

export enum SearchBy {
  uuid = 'uuid',
  title = 'title',
  sid = 'sid',
  orgUuid = 'orgUuid',
  customerPhoneNumber = 'customerPhoneNumber',
  customerEmail = 'customerEmail'
}

export enum OS {
  Windows = 'Windows',
  MacOS = 'MacOS',
  UNIX = 'UNIX',
  Linux = 'Linux'
}

export const initStateMyWorksV2 = () =>
  <MyWorkV2>{
    assigned: <TxnFilterStateV1>{
      txnFilter: <FilterTxnFormGroupV1>{},
      state: {
        loaded: false,
        page: 1,
        perPage: PER_PAGE_TXN,
        hasMore: false
      },
      txnUuidsFilter: [],
      loadedFirst: false
    },
    createdByMe: <TxnFilterStateV1>{
      txnFilter: <FilterTxnFormGroupV1>{},
      state: {
        loaded: false,
        page: 1,
        perPage: PER_PAGE_TXN,
        hasMore: false
      },
      txnUuidsFilter: [],
      loadedFirst: false
    },
    watcher: <TxnFilterStateV1>{
      txnFilter: <FilterTxnFormGroupV1>{},
      state: {
        loaded: false,
        page: 1,
        perPage: PER_PAGE_TXN,
        hasMore: false
      },
      txnUuidsFilter: [],
      loadedFirst: false
    },
    mention: <TxnFilterStateV1>{
      txnFilter: <FilterTxnFormGroupV1>{},
      state: {
        loaded: false,
        page: 1,
        perPage: PER_PAGE_TXN,
        hasMore: false
      },
      txnUuidsFilter: [],
      loadedFirst: false
    },
    worked_on: <TxnFilterStateV1>{
      txnFilter: <FilterTxnFormGroupV1>{},
      state: {
        loaded: false,
        page: 1,
        perPage: PER_PAGE_TXN,
        hasMore: false
      },
      txnUuidsFilter: [],
      loadedFirst: false
    },
    inboxes: <TxnFilterStateV1>{
      txnFilter: <FilterTxnFormGroupV1>{},
      state: {
        loaded: false,
        page: 1,
        perPage: PER_PAGE_TXN,
        hasMore: false
      },
      txnUuidsFilter: [],
      loadedFirst: false
    }
  };
