import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CommonModule } from '@angular/common';
import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { flattenDeep } from 'lodash';
import { debounceTime, takeUntil } from 'rxjs';
import { DestroySubscriberComponent } from '../destroy-subscriber.component';
import { FilterItemSearch, ResourceItem, SEARCH_ANY, TypeField } from './single-search-bar.model';

@Component({
  selector: 'shc-single-search-bar',
  templateUrl: './single-search-bar.component.html',
  styleUrls: ['./single-search-bar.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatIconModule,
    MatInputModule,
    MatCheckboxModule,
    MatAutocompleteModule
  ]
})
export class SingleSearchBarComponent
  extends DestroySubscriberComponent
  implements OnChanges, OnInit, AfterViewChecked
{
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('searchInput') inputField: ElementRef;
  @ViewChild('auto') autocomplete: MatAutocomplete;
  @ViewChild('scrollContainer') scrollContainer: ElementRef | undefined;

  @Input() filterResource: FilterItemSearch[] = [];
  @Input() hideSearchAny: boolean = false;
  @Input() className: string = '';
  @Input() descriptionSearchAny: string = ''; // Description search ANY
  @Input() pairOfKeysFilter: string[][] = [];
  @Output() onChangeSingleSearchBar: EventEmitter<FilterItemSearch[]> = new EventEmitter();

  readonly searchAny = SEARCH_ANY;

  document = document;
  typeField = TypeField;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  searchCtrl = new FormControl('');
  filterItemResource: FilterItemSearch[] = [];
  filterItemResourceOriginal: FilterItemSearch[] = [];
  listItemSearched: FilterItemSearch[] = [];
  dataOfItemFilter: ResourceItem[] = [];
  selectedMultiItems: ResourceItem[] = []; // List items selected of the item filter (multiple selection)
  isShowFilter = true; // Check show dropdown filters
  isReadOnlyInput: boolean = true;
  isMultiSelect: boolean;
  isFocused: boolean;
  isEnterSearch: boolean;
  isSearchANY: boolean;
  index: number;
  isResized: boolean = false;
  clickInsideAutoComplete: boolean;

  @HostListener('document:keydown.enter', ['$event'])
  onEnter(event: KeyboardEvent) {
    if (this.autocompleteTrigger.autocomplete.isOpen && this.isEnterSearch) {
      this.clickInsideAutoComplete = false;
      this.autocompleteTrigger.closePanel();
      this.inputField.nativeElement.blur();
    }
  }

  constructor() {
    super();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filterResource'].currentValue) {
      this.filterItemResource = structuredClone(this.filterResource);
      this.filterItemResourceOriginal = structuredClone(this.filterResource);
      this._resetFilter();
      this.listItemSearched = [];
    }
  }

  ngOnInit(): void {
    this.searchCtrl.valueChanges.pipe(debounceTime(300), takeUntil(this.destroySubscriber$)).subscribe(value => {
      if (value && (!this.listItemSearched?.length || this.listItemSearched.every(item => item.value))) {
        this.isSearchANY = true;
        this.isShowFilter = false;
        this.dataOfItemFilter = [{ key: '', value: this.descriptionSearchAny }];
      } else {
        if (this.isSearchANY) {
          this.dataOfItemFilter = [];
          this.isShowFilter = true;
        }
        this.isSearchANY = false;
      }
    });
  }

  ngAfterViewInit(): void {
    // Add a global click listener to detect if the user clicks on the autocomplete panel
    document.addEventListener('mousedown', this.onDocumentClick.bind(this));
  }

  ngAfterViewChecked() {
    this.scrollToEnd();
  }

  // Function to scroll to the end of the container
  scrollToEnd() {
    const container = this.scrollContainer?.nativeElement;
    if (container) {
      container.scrollLeft = container.scrollWidth; // Scroll to the very end
    }
  }

  // Handle document clicks to detect if the click is inside the autocomplete panel
  onDocumentClick(event: MouseEvent): void {
    this.clickInsideAutoComplete = this.autocomplete?.panel?.nativeElement.contains(event.target);
  }

  resizeField(isFocused: boolean): void {
    const matFormField = document.querySelector('.resize-form-field');

    this.isFocused = isFocused;
    if (isFocused) {
      // Add the class to increase width when focused

      !matFormField?.className?.includes('fixed-width') &&
        !this.hideSearchAny &&
        setTimeout(() => {
          this.isReadOnlyInput = false;
        }, 200);
      matFormField?.classList.add('fixed-width');
      this.isResized = true; // Set resized state
    } else if (this.isResized) {
      // Keep the width fixed even after losing focus
      matFormField?.classList.add('fixed-width');
      // If not have value, remove the width fixed even after losing focus
      if (!this.listItemSearched?.length && !this.clickInsideAutoComplete) {
        matFormField?.classList.remove('fixed-width');
        this.isReadOnlyInput = true;
        this.autocompleteTrigger.closePanel();
      }
    }
  }

  onFocus() {
    (this.isShowFilter || this.dataOfItemFilter?.length) &&
      !this.autocompleteTrigger.autocomplete.isOpen &&
      this._setTimeoutOpenPannel(200);
  }

  selected(event: MatAutocompleteSelectedEvent) {
    // Select item filter
    if (this.isShowFilter) {
      this.listItemSearched.push(event.option.value);

      // Filter resource selected and sort by order
      this.updateResourceFilter(event);
      this.filterItemResource.sort((a, b) => a.order - b.order);

      this.dataOfItemFilter = event.option.value.data || [];
      this.isShowFilter = false;
      this.isReadOnlyInput = !(event.option.value.type === TypeField.input);

      setTimeout(() => {
        this.dataOfItemFilter?.length && this._setTimeoutOpenPannel(0);
        this.isMultiSelect = event.option.value.type === TypeField.multiSelect;
      }, 100);

      // Handle filter type boolean
      if (typeof event.option.value?.value === 'boolean') {
        this.listItemSearched[this.index ?? this.listItemSearched?.length - 1].value = true;
        setTimeout(() => {
          this.isEnterSearch = true;
        }, 0);
        this._resetFilter();
      }
    } else {
      // Select data of item filter (Selection)
      this.listItemSearched[this.index ?? this.listItemSearched?.length - 1].value = event.option.value?.value;
      this.listItemSearched[this.index ?? this.listItemSearched?.length - 1].valueResourceKey = event.option.value?.key;
      this._resetFilter();
      this.isShowFilter = !this.listItemSearched?.some(item => item.type === TypeField.input && !item.value);
      setTimeout(() => {
        this.isEnterSearch = true;
      }, 0);
      this.isReadOnlyInput = this.hideSearchAny
        ? true
        : this.listItemSearched?.some(item => item.key === SEARCH_ANY || (item.type === TypeField.input && item.value));
      this._setTimeoutOpenPannel(0);
    }
    this.searchCtrl.setValue(null);
  }

  updateResourceFilter(event: MatAutocompleteSelectedEvent) {
    this.filterItemResource = this.filterItemResource.filter(item => item.key !== event.option.value?.key);
    if (event.option.value.type === TypeField.input) {
      if (this.pairOfKeysFilter.length) {
        let pairOfKeys = [];

        this.pairOfKeysFilter.forEach(item => {
          item.includes(event.option.value?.key) &&
            pairOfKeys.push(item.filter(key => key !== event.option.value?.key));
        });

        this.filterItemResource = this.filterItemResource.filter(
          item => flattenDeep(pairOfKeys).includes(item.key) || item.type !== TypeField.input
        );
      } else {
        this.filterItemResource = this.filterItemResource.filter(item => item.type !== TypeField.input);
      }
    }
  }

  addInput(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add item
    if (this.listItemSearched?.length && !this.listItemSearched[this.index ?? this.listItemSearched.length - 1].value) {
      this.listItemSearched[this.index ?? this.listItemSearched.length - 1].value = value;
      this.isReadOnlyInput = true;
      this.handleEmitData(event);
    } else {
      // Search for Any
      this.listItemSearched.push({ key: SEARCH_ANY, value: value, info: '', type: TypeField.input, order: null });
      this.isReadOnlyInput = true;
      this.filterItemResource = this.filterItemResource.filter(item => item.type !== TypeField.input);
      this.handleEmitData(event);
    }
    this.filterItemResource.sort((a, b) => a.order - b.order);
  }

  handleEmitData(event: MatChipInputEvent) {
    this.isShowFilter = true;
    this._resetFilter();
    setTimeout(() => {
      this.isSearchANY && this.inputField.nativeElement.focus();
      this.isEnterSearch = true;
    }, 0);

    // Clear the input value
    event.chipInput!.clear();
    this.searchCtrl.setValue(null);
  }

  edit(data: FilterItemSearch, index: number) {
    this.isReadOnlyInput = data.type !== TypeField.input;
    this.index = index;
    this.isShowFilter = false;
    if (data.type === TypeField.multiSelect || data.type === TypeField.singleSelect) {
      this.dataOfItemFilter = data.data || [];
      setTimeout(() => {
        this.dataOfItemFilter?.length && this._setTimeoutOpenPannel(0);
        this.isMultiSelect = data.type === TypeField.multiSelect;
      }, 200);
    } else {
      this.dataOfItemFilter = [];
      this.inputField.nativeElement.focus();
    }
  }

  remove(item: FilterItemSearch) {
    item.value = '';
    this.autocompleteTrigger.openPanel();
    this.inputField.nativeElement.focus();
    const index = this.listItemSearched.indexOf(item);

    if (index >= 0) {
      this.listItemSearched.splice(index, 1);
    }

    if (item.type === TypeField.multiSelect) {
      this.selectedMultiItems = [];
      this.isMultiSelect = false;
      item.data.forEach(e => {
        e.selected = false;
      });
    }
    this._updateFilterResourceAfterRemove(item);
    this.isShowFilter = !this.listItemSearched?.some(item => item.type === TypeField.input && !item.value);
    this.isReadOnlyInput = this.listItemSearched?.length
      ? this.listItemSearched?.some(item => item.key === SEARCH_ANY || (item.type === TypeField.input && item.value))
      : this.hideSearchAny;
    this._resetFilter();
  }

  onSearch() {
    setTimeout(() => {
      !this.autocompleteTrigger.autocomplete.isOpen &&
        !this.clickInsideAutoComplete &&
        !this.isFocused &&
        this.onChangeSingleSearchBar.emit(this.listItemSearched);
    }, 300);
  }

  // Start handle multi select
  optionClicked(event: Event, data: ResourceItem) {
    event.stopPropagation();
    this.toggleSelection(data);
  }

  toggleSelection(data: ResourceItem) {
    data.selected = !data.selected;
    if (data.selected) {
      this.selectedMultiItems.push(data);
    } else {
      const i = this.selectedMultiItems.findIndex(item => item.key === data.key && item.value === data.value);
      this.selectedMultiItems.splice(i, 1);
    }
    this.listItemSearched[this.index ?? this.listItemSearched?.length - 1].value = this.selectedMultiItems
      .map(item => item.value)
      .join(', ');
  }
  // End handle multi select

  closedAutoComplete() {
    // Multiselect
    if (this.isMultiSelect && this.selectedMultiItems?.length) {
      this.isMultiSelect = false;
      this._resetFilter();
    }

    if (!this.isFocused) {
      this.onSearch();
      setTimeout(() => {
        if (
          (!this.dataOfItemFilter?.length && this.isReadOnlyInput) ||
          this.listItemSearched?.every(
            item => (item.type === TypeField.singleSelect || item.type === TypeField.multiSelect) && item.value
          )
        ) {
          this.isShowFilter = true;
        }
      }, 200);
    }
  }

  clearAllSearchFilter() {
    this.listItemSearched = [];
    this.dataOfItemFilter = [];
    this.isShowFilter = true;
    this.isEnterSearch = false;
    this.isReadOnlyInput = this.hideSearchAny;
    this.filterItemResource = structuredClone(this.filterItemResourceOriginal);
    this.onSearch();
  }

  private _setTimeoutOpenPannel(timeout: number) {
    setTimeout(() => {
      this.autocompleteTrigger.openPanel();
    }, timeout);
  }

  private _resetFilter() {
    this.index = null;
    this.dataOfItemFilter = [];
    this.isEnterSearch = false;
  }

  private _updateFilterResourceAfterRemove(filter: FilterItemSearch) {
    if (filter.key !== SEARCH_ANY) {
      if (filter.type !== TypeField.input) {
        this.filterItemResource.push(filter);
      } else {
        this.filterItemResourceOriginal.forEach(item => {
          filter.value = '';
          filter.valueResourceKey = '';
          item.type === TypeField.input &&
            !this.listItemSearched.find(s => s.key === item.key) &&
            this.filterItemResource.push(filter);
        });
      }
    } else {
      this.filterItemResourceOriginal.forEach(item => {
        filter.value = '';
        filter.valueResourceKey = '';
        !this.listItemSearched.find(s => s.key === item.key) && this.filterItemResource.push(filter);
      });
    }
    this.filterItemResource = [...new Map(this.filterItemResource.map(item => [item.key, item])).values()];
    this.filterItemResource.sort((a, b) => a.order - b.order);
  }
}
