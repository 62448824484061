import { HashMap } from '@datorama/akita';

export enum FailOverType {
  sipEndpoint = 'sipEndpoint',
  backupNumber = 'backupNumber'
}

export interface IncomingFailoverConfig {
  strategy: FailOverType;
  // manual
  active?: boolean;
  defaultBackupNumber?: string;
  backupNumbers?: HashMap<string>;

  // auto
  sipUsername?: string;
  sipDomain?: string;
}

export interface updateHAToSipTrunkParams {
  isRemoveHA: boolean;
  secondarySipUsername: string;
}

export enum TypeSipAccount {
  SIP_TRUNK = 'SIP_TRUNK',
  BYOC_TRUNK = 'BYOC_TRUNK',
  BYOP_TRUNK = 'BYOP_TRUNK',
  EMPTY = 'NONE',
  ORG_CONNECTOR = 'ORG_CONNECTOR',
  CARRIER_CONNECTOR = 'CARRIER_CONNECTOR'
}

export interface ConnectorConfig {
  carrierConnectApps: number[];
  customHeaders: HashMap<string>;
  enableCarrierConnectApp: boolean;
  carrierConnectors?: string[];
  orgConnectorParty?: string;
  orgConnectorOrgUuid?: string;
}

export enum SipAccountStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED'
}

export class SipAccount {
  id: string;
  type: TypeSipAccount;
  orgUuid: string;
  sipAccount: {
    username: string;
    domain: string;
    serverPort: string;
    protocol: string;
    registrationInterval: string;
    codec: string;
    stunServer: string;
    enableIpv6: boolean;
    password: string;
    fqdn?: string;
  };
  byopConfig?: {
    dnisFormat: string;
  };
  outgoingCallRule: number;
  incomingCallRule: number;
  callerIdConfig: {
    defaultCallerId: string;
    mode: CallerIdOptionMode;
    mainTrunkNumber: string;
  };
  createdTime: string;
  status: SipAccountStatus;
  pendingConnectOrg?: boolean;
  ipWhiteList: any[];

  incomingFailoverConfig?: IncomingFailoverConfig;
  detail: DetailSipAccount;
  highAvailabilityConfig: HighAvailabilityConfig;
  connectorConfig: ConnectorConfig;
  // fetch api
  numbers: string[];
  concurrentCalls?: number;
  isHA: boolean;
  isIP: boolean;
  isCarrierConnect: boolean;
  usersCanAccess?: string[];
  isSecondary: boolean;
  constructor(obj?: Partial<SipAccount>) {
    if (obj) {
      Object.assign(this, obj);
    }
  }

  get sipUsername() {
    return this.sipAccount?.username || this.id;
  }

  get displayNumbers() {
    return this.numbers?.length > 1 ? this.numbers?.join(' \n ') : '';
  }

  get hasSecondarySip() {
    return this.highAvailabilityConfig?.secondarySips?.length > 0;
  }

  get displayName() {
    const label = this.detail?.label ? this.detail.label + ' - ' : '';
    const number =
      this.numbers?.length === 0 || !this.numbers
        ? ''
        : this.numbers?.length === 1
          ? `(${this.numbers[0]})`
          : `(${this.numbers?.length} numbers)`;
    return `${label}${this.sipUsername} ${number}`;
  }

  get labelAndNumber() {
    const label = this.detail?.label ? this.detail.label : '';
    const number =
      this.numbers?.length === 0 || !this.numbers
        ? ''
        : this.numbers?.length === 1
          ? `- (${this.numbers[0]})`
          : `- (${this.numbers?.length} numbers)`;
    return `${label} ${number}`;
  }
}

export interface HighAvailabilityConfig {
  mode: HighAvailabilityMode;
  secondarySips: string[];
}

export enum HighAvailabilityMode {
  loadBalancing = 'loadBalancing',
  autoFailover = 'autoFailover',
  onlyPrimary = 'onlyPrimary'
}

export interface RoutingConfigSip {
  forwardTo: string;
  number: string;
}

export interface BYOPTrunkMapping {
  extKey: string;
  byopNumber: string;
  assignedNumbers?: string[];
  oldExtKey?: string;
  type?: DeviceType;
}

export enum DeviceType {
  BYOP = 'BYOP',
  BYOPH = 'BYOPH'
}

export enum CallerIdOptionMode {
  NORMAL = 'NORMAL',
  SIP_NUMBER = 'SIP_NUMBER',
  PBX = 'PBX'
}

export interface DetailSipAccount {
  type: string;
  username: string;
  domain: string;
  password: string;
  oldPasswords: string[];
  label: string;
  status: string;
  capacity: string;
  owner: {
    uuid: string;
    name: string;
    email: string;
  };
  cps: number;
  publicIp: any;
  localIp: any;
  handleIsdnIncoming: boolean;
  numbers: any[];
  config: Config;
  subscriptionUuid: string;
  enterprise: boolean;
  identityUuid?: string;
  device?: string;
}

export enum AuthenticationMode {
  ACCOUNT = 'ACCOUNT',
  IP = 'IP'
}

export interface Config {
  callerId: string;
  ipWhiteList: any[];
  countryWhiteList: any[];
  authenticationMode: AuthenticationMode;
  ipPeers: any[];
  callerIdPrepend: null;
  callerIdRules: null;
}

export interface ReqUpdateIpPeer {
  ip?: string;
  protocol?: string;
  port?: string;
}

export interface GETBYOPTrunkMappingReq {
  keyword: string;
}

export interface CarrierConnectApp {
  id: number;
  name: string;
  routingPlan: string;
  info: CarrierConnectAppInfo;
}

export interface CarrierConnectAppInfo {
  logo: string;
}

export interface ParamsConnectSipToApp {
  sip: SipAccount;
  type: TypeSipAccount;
  orgUuid?: string | '';
  connectUuid?: string | '';
  orgName?: string | '';
}
