import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IdentityProfile, IdentityProfileQuery, OrgConnection, OrgLinkService } from '@b3networks/api/auth';
import { APP_IDS, DEFAULT_ORG_LOGO, DestroySubscriberComponent } from '@b3networks/shared/common';
import { ConfirmDialogComponent, ConfirmDialogInput } from '@b3networks/shared/ui/confirm-dialog';
import { ToastService } from '@b3networks/shared/ui/toast';
import { cloneDeep } from 'lodash';
import { Observable, combineLatest } from 'rxjs';
import { filter, finalize, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { OrgLinkInviteComponent } from './org-link-invite/org-link-invite.component';

@Component({
  selector: 'b3n-org-link',
  templateUrl: './org-link.component.html',
  styleUrls: ['./org-link.component.scss']
})
export class OrgLinkComponent extends DestroySubscriberComponent implements OnInit {
  DEFAULT_ORG_LOGO = DEFAULT_ORG_LOGO;
  MAX_MEMBER_NUMBER = 4;

  profile: IdentityProfile;
  form: UntypedFormGroup;
  orgLinks: OrgConnection[];
  orgLinksFilter: OrgConnection[];
  displayedColumns: string[] = ['uuid', 'organization', 'created', 'status', 'action'];
  dataSource: MatTableDataSource<OrgConnection>;
  addNew: boolean;
  currentOrgUuid: string;
  loading = true;
  pageSize = 5;
  statusPendingCount = 0;
  appID: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  readonly APP_IDS = APP_IDS;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private orgLinkService: OrgLinkService,
    private identityProfileQuery: IdentityProfileQuery,
    private fb: UntypedFormBuilder,
    private toastService: ToastService,
    public dialog: MatDialog
  ) {
    super();
    this.currentOrgUuid = this.identityProfileQuery.currentOrg.orgUuid;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const width = event.target.innerWidth;

    if (width <= 950) {
      this.MAX_MEMBER_NUMBER = 1;
    } else if (width <= 1150) {
      this.MAX_MEMBER_NUMBER = 2;
    } else if (width <= 1350) {
      this.MAX_MEMBER_NUMBER = 3;
    } else {
      this.MAX_MEMBER_NUMBER = 4;
    }
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => (this.appID = data['appID']));

    this.initForm();
    this.identityProfileQuery.profile$
      .pipe(
        takeUntil(this.destroySubscriber$),
        filter(profile => !!profile),
        tap(profile => {
          this.profile = profile;
          this._loadData();
        })
      )
      .subscribe();
  }

  initForm() {
    this.form = this.fb.group({
      uuid: [''],
      status: ['ACTIVE']
    });

    combineLatest([
      <Observable<string>>this.form.controls['uuid'].valueChanges.pipe(startWith('')),
      <Observable<string>>this.form.controls['status'].valueChanges.pipe(startWith(''))
    ])
      .pipe(
        map(([uuid, status]) => [uuid.trim().toLowerCase(), status]),
        tap(([uuid, status]) => {
          if (!this.orgLinks) {
            return;
          }

          this.orgLinksFilter = this.orgLinks.filter(
            o =>
              o.uuid.trim().toLowerCase().includes(uuid) &&
              o.status.includes(status || this.form.controls['status'].value)
          );
          this.dataSource = new MatTableDataSource(this.orgLinksFilter);
          this.dataSource.paginator = this.paginator;
        })
      )
      .subscribe();
  }

  accept(connectUuid: string) {
    this.dialog
      .open(ConfirmDialogComponent, {
        width: '400px',
        data: <ConfirmDialogInput>{
          title: 'Accept join request',
          message: 'Are you sure you want to join this connection?',
          cancelLabel: 'No',
          confirmLabel: 'Yes'
        },
        disableClose: true
      })
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.orgLinkService.acceptConnection(this.profile.uuid, connectUuid).subscribe({
            next: () => {
              this.toastService.success('Accept successfully');
              this._loadData();
            },
            error: err => this.toastService.warning(err.message)
          });
        }
      });
  }

  deny(connectUuid: string) {
    this.dialog
      .open(ConfirmDialogComponent, {
        width: '400px',
        data: <ConfirmDialogInput>{
          title: 'Deny join request',
          message: 'Are you sure you want to reject this connection?',
          cancelLabel: 'No',
          confirmLabel: 'Yes'
        },
        disableClose: true
      })
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.orgLinkService.denyConnection(this.profile.uuid, connectUuid).subscribe({
            next: () => {
              this.toastService.success('Deny successfully');
              this._loadData();
            },
            error: err => this.toastService.warning(err.message)
          });
        }
      });
  }

  onConnectDetail(data: OrgConnection) {
    this.router.navigate(
      [
        data.uuid,
        {
          name: this.currentOrgUuid === data.initiator.uuid ? data.recipient.name : data.initiator.name,
          recipientOrg: data.recipient?.uuid,
          initiatorOrg: data.initiator.uuid
        }
      ],
      {
        relativeTo: this.route
      }
    );
  }

  openOrgLinkInvite() {
    this.dialog
      .open(OrgLinkInviteComponent, {
        data: {
          profileUuid: this.profile.uuid,
          type: 'Create'
        },
        disableClose: true,
        width: '450px'
      })
      .afterClosed()
      .subscribe(addNew => {
        if (addNew) {
          this._loadData();
          this.dataSource.paginator.lastPage();
        }
      });
  }

  reinvite(orgUuid: string) {
    this.orgLinkService.createConnection(this.profile.uuid, orgUuid).subscribe({
      next: () => {
        this._loadData();
        this.dataSource.paginator.lastPage();
        this.toastService.success('Invite successfully');
      },
      error: err => {
        err.message?.toLocaleLowerCase().includes('established')
          ? this.toastService.warning("You're already connected with this Organization.")
          : this.toastService.warning(err.message);
      }
    });
  }

  copied() {
    this.toastService.success('Copied to clipboard');
  }

  private _loadData() {
    this.orgLinkService
      .getListConnections()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(data => {
        data = data.map(item =>
          Object.assign({}, item, {
            isRecipientOrg: item.recipient.uuid === this.profile.organizations[0].orgUuid && item.status === 'PENDING'
          })
        );
        this.orgLinks = data;
        this.statusPendingCount = data.filter(item => item.status === 'PENDING').length;
        this.orgLinksFilter = cloneDeep(data.filter(item => item.status === this.form.controls['status'].value));
        this.dataSource = new MatTableDataSource(this.orgLinksFilter);
        this.dataSource.paginator = this.paginator;
      });
  }

  get orgUuid() {
    return this.form.controls['orgUuid'];
  }
}
